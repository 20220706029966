<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              Preview New Budget
            </h2>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->

    <b-card
      class="mb-3 p-2 calllog-details serviceForm_details"
    >
      <b-table-simple
        borderless
        responsive
        class="particulars_of_deceased d-none d-md-block"
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Cost Centre
            </b-th>
            <b-td>{{ resolveCostCenterName(costCenter) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Budgetary Period
            </b-th>
            <b-td>{{ resolveBudgetaryPeriodName(budgetaryPeriod) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Expense Category Type
            </b-th>
            <b-td>
              {{ expenseCategoryType }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-table-simple
        borderless
        responsive
        class="particulars_of_deceased d-block d-md-none"
      >
        <b-tbody>
          <b-tr>
            <b-th width="230px">
              Cost Centre
            </b-th>
            <b-td>{{ resolveCostCenterName(costCenter) }}</b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Budgetary Period
            </b-th>
            <b-td> {{ resolveBudgetaryPeriodName(budgetaryPeriod) }} </b-td>
          </b-tr>
          <b-tr>
            <b-th width="230px">
              Expense Category Type
            </b-th>
            <b-td>
              {{ expenseCategoryType }}
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>

    <b-card
      no-body
      class="mb-3 p-2"
    >
      <b-table
        ref="refAllRelatedInventoryRequestsItemTable"
        class="position-relative has_padding event_list"
        :items="budgetSetupData"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(stringID)="data">
          <span
            class="text-bold-black"
            :class="data.item.error ? 'text-danger' : ''"
          >{{ data.item.stringID }}</span>
        </template>
        <template #cell(code)="data">
          <span
            :class="data.item.error ? 'text-danger' : ''"
          >{{ data.item.code }}</span>
        </template>
        <template #cell(name)="data">
          <span
            :class="data.item.error ? 'text-danger' : ''"
          >{{ data.item.name }}</span>
        </template>
        <template #cell(allocated)="data">
          <span
            :class="data.item.error ? 'text-danger' : ''"
          >{{ numberFormat(data.item.allocated) }}</span>
        </template>
      </b-table>
    </b-card>

    <b-card v-if="errorItems.length > 0">
      <p>A few errors found, please check your data again:</p>
      <ul style="list-style: none; padding: 0; color: #D91B35;">
        <li
          v-for="error in errorItems"
          :key="error.stringID"
        >
          <span v-if="error.allocated < 0">{{ `${error.stringID} cannot be less than S$0` }}</span>
          <span v-else>{{ `${error.stringID} and budget code ${error.code} not match in Expense Categories database` }}</span>
        </li>
      </ul>
    </b-card>

    <b-nav class="wrap-border save-nav">
      <li
        class="nav-item ml-auto"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="flat-primary"
          :to="{ name: 'budgeting-budget-setup-create' }"
        >
          Reupload
        </b-button>
      </li>
      <li
        class="nav-item mr-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          type="button"
          :disabled="errorItems.length > 0"
          @click="importToSystem()"
        >
          <feather-icon
            icon="ArrowRightIcon"
            class="mr-50"
          />
          <span class="align-middle">Import to system</span>
        </b-button>
      </li>
    </b-nav>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BTableSimple, BTable, BTbody, BTr, BTh, BTd, BNav,
  VBToggle, VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import ToastificationContentCustom from './ToastificationContentCustom.vue'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BTable,
    BNav,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  data() {
    return {
      submitPreviewExcel: [],
      budgetSetupData: JSON.parse(localStorage.getItem('budgetItems')) ?? [],
      previousFetchBudgetItems: JSON.parse(localStorage.getItem('fetchedBudgetItems')) ?? [],
      errorItems: [],
      costCenter: localStorage.getItem('costCenter'),
      budgetaryPeriod: localStorage.getItem('budgetaryPeriod'),
      expenseCategoryType: localStorage.getItem('expenseCategoryType'),
      attachments: JSON.parse(localStorage.getItem('budgetSetupAttachments')),
      costCenterOptions: [],
      budgetaryPeriodOptions: [],
      sortBy: 'stringID',
      sortDesc: false,
      isSortDirDesc: false,
      tableColumns: [
        {
          key: 'stringID',
          label: 'id',
          sortable: true,
        },
        {
          key: 'code',
          label: 'budget code',
          sortable: false,
        },
        {
          key: 'name',
          label: 'expense category',
          sortable: false,
        },
        {
          key: 'allocated',
          label: 'annual budget amount (S$)',
          sortable: false,
        },
      ],
      required,
    }
  },
  beforeMount() {
    this.$http.get('budget/cost-center/respond-with/name-and-id')
      .then(response => {
        this.costCenterOptions = response.data.costCenters
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    this.$http.get('budget/budget-period/respond-with/name-and-id')
      .then(response => {
        this.budgetaryPeriodOptions = response.data.budgetPeriods
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    resolveCostCenterName(id) {
      const found = this.costCenterOptions.find(ob => ob._id === id)
      if (found) {
        return found.name
      }
      return ''
    },

    resolveBudgetaryPeriodName(id) {
      const found = this.budgetaryPeriodOptions.find(ob => ob._id === id)
      if (found) {
        return found.name
      }
      return ''
    },

    importToSystem() {
      this.budgetSetupData.forEach(uploadedData => {
        const matchData = this.previousFetchBudgetItems.find(previousData => previousData.stringID === uploadedData.stringID && previousData.code === uploadedData.code)
        if (matchData) {
          if (Number(uploadedData.allocated) < 0) {
            this.errorItems.push({
              stringID: uploadedData.stringID,
              code: uploadedData.code,
              name: uploadedData.name,
              allocated: uploadedData.allocated,
            })
            // eslint-disable-next-line no-param-reassign
            uploadedData.error = true
          }
        } else {
          this.errorItems.push({
            stringID: uploadedData.stringID,
            code: uploadedData.code,
            name: uploadedData.name,
            allocated: uploadedData.allocated,
          })
          // eslint-disable-next-line no-param-reassign
          uploadedData.error = true
        }
      })

      if (!this.errorItems.length) {
        const formData = new FormData()
        const stringIDs = []
        this.budgetSetupData.forEach(sID => {
          stringIDs.push(sID.stringID)
        })
        formData.append('expenseCategoryType', this.expenseCategoryType)
        formData.append('costCenter', this.costCenter)
        formData.append('budgetPeriod', this.budgetaryPeriod)
        formData.append('budgetAmount', this.budgetSetupData.reduce((prev, current) => prev + Number(current.allocated), 0))
        formData.append('categoryIDs', JSON.stringify(stringIDs))
        formData.append('items', JSON.stringify(this.budgetSetupData))
        formData.append('attachments', JSON.stringify(this.attachments))
        this.$http.post('budget/budget/store', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
          .then(() => {
            this.$swal({
              title: 'Budget Setup Created Successfully!',
              html: '',
              // eslint-disable-next-line global-require
              imageUrl: require('@/assets/images/icons/success.png'),
              imageWidth: 80,
              imageHeight: 80,
              imageAlt: 'Custom Icon',
              showCancelButton: false,
              confirmButtonText: 'Okay',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
              .then(result => {
                if (result.value) {
                  localStorage.removeItem('budgetItems')
                  localStorage.removeItem('fetchedBudgetItems')
                  localStorage.removeItem('costCenter')
                  localStorage.removeItem('budgetaryPeriod')
                  localStorage.removeItem('expenseCategoryType')
                  localStorage.removeItem('budgetSetupAttachments')
                  this.$router.push({ name: 'budgeting-budget-setup', query: { costCenter: this.costCenter } })
                }
              })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    },
  },
}
</script>
